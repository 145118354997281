<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale"  style="overflow:auto;height:700px;">
            <!-- 顶部导航 -->
            <div class="box-card" >
                 <!-- 按钮 -->
                <!-- <el-row style="height:40px;">
                    <el-button type="primary" class="buttons" @click="exportData">导出</el-button>
                </el-row> -->
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <!-- <el-form-item label="店铺" >
                        <el-select v-model="req.s" placeholder="请选择" style="width:120px;">
                            <el-option
                                    v-for="item in statusList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->

                    <el-form-item label="创建时间">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker 
                                v-model="req.start"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker 
                                v-model="req.end"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>

                    <el-form-item label="创建时间">
                        <el-button  @click="setDate('is_today')">今日</el-button>
                        <el-button @click="setDate('is_seven_today')">近7日</el-button>
                        <el-button @click="setDate('is_thirty_today')">近30日</el-button>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="getList">筛选</el-button>
                    </el-form-item>
                </el-form>
                
                <div class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">销售走势 </div>
                </div>
                <!-- 顶部5个banner -->
                <div class="top_banner">
                    <div class="item">
                        <div class="disc">销售额（元）</div>
                        <div class="num">{{setPrice(this.showData.sales_total)}}</div>
                    </div>
                    <div class="item">
                        <div class="disc">销售笔数</div>
                        <div class="num">{{this.showData.sales_count}}<span v-if="this.showData.sales_count === undefined">0</span></div>
                    </div>
                    <div class="item">
                        <div class="disc">销售利润（元）</div>
                        <div class="num">{{setPrice(this.showData.sales_profit)}}</div>
                    </div>
                    <div class="item">
                        <div class="disc">销售退款笔数</div>
                        <div class="num">{{setPrice(this.showData.sales_back_count)}}</div>
                    </div>
                    <div class="item">
                        <div class="disc">销售退款金额（元）</div>
                        <div class="num">{{setPrice(this.showData.sales_back_total)}}</div>
                    </div>
                </div>
                <!-- 折线图 -->
                <div  style="height:300px;max-width:1400px;margin-top:20px;margin-left:20px;">
                    <div class="lineChart" style="width:100%;height:300px;"></div>
                </div>
                <div style="height:30px;"></div>
                <div class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">商品销售品类占比  </div>
                </div>
                <!-- 饼图 -->
                <div class="cookieChart" style="height:300px;max-width:1400px;margin-top:20px;margin-left:20px;">
                    <div class="pieChart" style="width:100%;height:300px;"></div>
                </div>
                <div style="height:30px;"></div>
                <!-- 销售排行 -->
                <div class="paihang" style="max-width:1400px;">
                    <div style="width:49%;display:inline-block;">
                        <div class="add_split">
                            <div class="leftBar"></div>
                            <div class="leftBarWord">商品销售品类占比  </div>
                        </div>
                    </div>
                    <div style="width:49%;display:inline-block;margin-left:15px;">
                        <div class="add_split">
                            <div class="leftBar"></div>
                            <div class="leftBarWord">商品退货排行   </div>
                        </div>

                        <div >
                        </div>
                    </div>
                </div>
                <div class="paihang" style="max-width:1400px;display:flex;">
                    <div style="width:49%;display:inline-block;">
                        <el-table
                            :data="paihang1"
                            stripe
                            style="width: 100%">
                            <el-table-column
                            prop="date"
                            label="商品编号">
                                <template slot-scope="scope">{{ scope.row.product_code }}</template>
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            label="商品名称">
                            <template slot-scope="scope">{{ scope.row.product_name }}</template>
                            </el-table-column>
                            <el-table-column
                            prop="address"
                            label="销售总额（元）">
                            <template slot-scope="scope">{{ scope.row.total }}</template>
                            </el-table-column>
                          <el-table-column
                            prop="address"
                            label="销售利润（元）">
                            <template slot-scope="scope">{{ scope.row.profit }}</template>
                            </el-table-column>
                            <el-table-column
                            prop="address"
                            label="销售笔数">
                            <template slot-scope="scope">{{ scope.row.count }}</template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="width:49%;display:inline-block;margin-left:15px;">
                    <el-table
                            :data="paihang2"
                            stripe
                            style="width: 100%">
                            <el-table-column
                            prop="date"
                            label="商品编号">
                                <template slot-scope="scope">{{ scope.row.product_code }}</template>
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            label="商品名称">
                            <template slot-scope="scope">{{ scope.row.product_name }}</template>
                            </el-table-column>
                            <el-table-column
                            prop="address"
                            label="退货总额（元）">
                            <template slot-scope="scope">{{ scope.row.total }}</template>
                            </el-table-column>
                          <el-table-column
                            prop="address"
                            label="退货利润（元）">
                            <template slot-scope="scope">{{ scope.row.profit }}</template>
                            </el-table-column>
                            <el-table-column
                            prop="address"
                            label="退货笔数">
                            <template slot-scope="scope">{{ scope.row.count }}</template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div style="height:180px;"></div>
        </div>
        <el-dialog
            :modal-append-to-body="false"
            title="导出数据"
            :visible.sync="exportExcelFlag"
            v-if="exportExcelFlag"
            width="60%"
            center>
                <div style="height:50px;width:100%;text-align:center;">
                    <i class="el-icon-loading" v-if="exportStatus===1" style="font-size:50px;color:#000;"></i>
                    <i class="el-icon-success" v-if="exportStatus===2" style="font-size:50px;color:green;"></i>
                </div>
                <div v-if="exportStatus===1" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据正在导出...稍后会自动下载，请留意下载工具
                </div>
                <div v-if="exportStatus===2" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据已导出完毕，本窗口即将关闭
                </div>
        </el-dialog>
        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加员工"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
            <createShare  :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>
        <!-- 页码 -->
        <!-- <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 75px 10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div> -->
        <!-- 分享列表 -->
        
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareList from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";

import * as echarts from 'echarts';

export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            uploadImages: [

            ],
            shopList:[],
            showData:{},
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            paihang1:[],
            paihang2:[],
            req:{
                start:'',
                end:'',
                page:1,
                limit:30,
                is_today:'',
                is_seven_today:'',
                is_thirty_today:''
            },
            exportStatus: 1,
            exportExcelFlag: false
        }
    },
    components:{
        createShare,shareList
    },
    methods:{
        init(){
            //数据源
            apis.data_analysis_all_source().then(res=>{
                if(tools.msg(res,this)){
                    this.statusList = res.data.shop;
                }
            }).catch(err=>{
                tools.err(err,this);
            });

        },
        

        LineChart(data){

            let series = [];

            for(let i=0;i<data.series.length;i++){
                let obj = {
                    name: data.series[i].name,
                    type: 'line',
                    stack: data.series[i].name,
                    data: data.series[i].data
                };

                series.push(obj);
            }
            //初始化echart
            let option = {
                title: {
                    text: '销售走势'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: data.data
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: data.xAxis
                },
                yAxis: {
                    type: 'value'
                },
                series: series
            };
            //转化数据

            option && echarts.init(document.querySelector('.lineChart')).setOption(option);
        },
        pieChart(data){
            // data = [
            //     {
            //         class_name:'a',
            //         num:1
            //     },
            //     {
            //         class_name:'b',
            //         num:5
            //     },
            //                     {
            //         class_name:'c',
            //         num:4
            //     }
            // ];
            let cate = [];
            for(let i=0;i<data.length;i++){
                let obj = {
                    name: data[i].class_name,
                    value: data[i].num,
                };
                cate.push(obj);
            }
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: '销售占比',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: cate
                    }
                ]
            };
            console.log(option);
            option && echarts.init(document.querySelector('.pieChart')).setOption(option);
        },
        //导出Excel
        exportData() {
            this.exportExcelFlag = true;
            apis.stock_report_warming_excel(this.req).then(res => {
                this.exportStatus = 2;
                let blob = new Blob([res]);
                let url = URL.createObjectURL(blob);
                setTimeout(() => {
                    this.exportExcelFlag = false;
                    this.exportStatus = 1;
                }, 2500);
                // 下载该blob链接
                tools.downloadFile(url, document.title + tools.getDateTime() + '.xls');
            }).catch(err => {
                console.log(err);
                // this.exportStatus = 3;
            });
        },
        setDate(field){
            this.reset();
            this.req[field] = 1;
            this.getList();
        },
        setPrice(price){
            return tools.setPrice(price);
        },
        onSubmit(){
            this.getList();
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        reset(){
            this.req = tools.copy(this.$options.data().req);
        },
        getList(){
            // 数据
            apis.data_analysis_all(this.req).then((res) => {
                if(tools.msg(res,this)){
                    this.showData = res.data;
                    this.paihang1 = this.showData.sale_list;
                    this.paihang2 = this.showData.sale_back_list;   
                    //初始化可视化图表
                    this.LineChart(this.showData.line_chart);
                    this.pieChart(this.showData.goods_classify);
                }
            }).catch((err) => {
                tools.err(err,this);
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.staffDelete({ id: id}).then((info) => {
                    if (info.code == 200) { 
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        this.getList();
    },
    mounted(){
        this.init();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
.add_split {
    border-bottom: 1px solid #f0f0f0;
    padding: 15px 0 15px 0;
    margin-bottom: 10px;
    position: relative;
    background: #fafafa;
    text-indent: 10px;
    .leftBar {
        width: 5px;
        height: 45px;
        background: #006EFF;
        position: absolute;
        left: 0px;
        top: 0px;
        display: inline-block;
    }   
}
.top_banner{
    .item{
        width:207px;height:120px;background: #f0f0f0;
        text-indent: 30px;display: inline-block;
        margin:10px 0 0 20px;
    }
    .disc{
        position:relative;top:30px;
    }
    .num{
        position:relative;font-size: 36px;top:48px;
        font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC';
        font-weight: 650;
        color: rgba(0, 110, 255, 0.898039215686275);
    }
}

</style>